<template>
  <div
      class="seperator extras"
      v-if="(selected_only === true && Object.keys(filtered_extras).length !== 0) || (filtered_product_extras.length !== 0 && selected_only === false)"
  >
    <div class="text-uppercase color-secondary">
      {{ $t("products.extras") }}
    </div>
    <div class="body">
      <!-- Each Extra -->
      <div
          class="row extra g-0"
          v-for="product_extra in filtered_product_extras"
          :key="product_extra.id"
      >
        <div class="col-auto Aligner" v-if="editable">
          <input-counter
              :min="0"
              :step="1"
              :disabled="disabled"
              :input_disabled="true"
              v-model="extras[product_extra.id]"
          />
        </div>

        <div class="col details Aligner items-left" :class="{'ps-2' : editable}">
          <div class="row g-0">
            <div
                class="col-auto Aligner items-left image"
                v-if="product_extra.image != null"
            >
              <div
                  class="ratio"
                  :style="
                      'background-image: url(' +
                      window.Routes.getImage(product_extra.image, 'preview') +
                      ');'
                    "
              ></div>
            </div>
            <div class="col Aligner items-left name">
              {{ product_extra.name }}
            </div>
          </div>
        </div>


        <div class="col-auto Aligner items-right color-secondary">
          <span v-if="extras[product_extra.id] !== undefined && extras[product_extra.id] !== 0">
            {{ formatPrice(product_extra.currency, product_extra.price) }} x {{ extras[product_extra.id] }}
          </span>
          <span v-else>
            0
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import formatPrice from "@/includes/currency";
import InputCounter from "@/components/CustomInputs/InputCounter";

export default {
  name: "CartProductExtras",
  components: {InputCounter},
  props: {
    modelValue: {
      type: Object
    },
    disabled: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    editable: {
      type: Boolean,
      default: () => {
        return true;
      }
    },
    selected_only: {
      type: Boolean,
      default: () => {
        return false;
      }
    },
    product_extras: {
      type: Object,
      default: () => {
        return {};
      }
    },
  },
  computed: {
    //Filter only selected extras
    filtered_extras(){
      //Remove any extra with a value of 0
      let extras = {};
      for (const extrasKey in this.extras) {
        if(this.extras[extrasKey] !== 0){
          extras[extrasKey] = this.extras[extrasKey];
        }
      }

     return extras;
    },
    //Filter all product extras
    filtered_product_extras(){
      if(this.selected_only === true){
        //Filter Extras
        return this.product_extras.filter((product_extra) => {
          return this.extras[product_extra.id] !== undefined && this.extras[product_extra.id] !== 0;
        })
      }

      return this.product_extras;
    }
  },
  data() {
    return {
      extras: []
    }
  },
  methods: {
    formatPrice(currency, price) {
      return formatPrice(currency, price);
    },
  },
  watch: {
    modelValue: function () {
      this.extras = this.modelValue;
    },
    extras: {
      handler() {
        //Calculate total price
        this.$emit('update:modelValue', this.extras)
      },
      deep: true,
    },
  },
  created() {
    this.extras = this.modelValue;
  }
}
</script>

<style lang="scss">
.extras {
  margin-top: map-get($spacings, "default");

  .extra {
    margin-top: 2px;
    margin-bottom: map-get($spacings, "small");

    .input-counter {
      width: 100px;
    }

    .image {
      margin-right: map-get($spacings, "small");

      & > .ratio {
        width: 40px;
        background-position: center;
        background-size: cover;
        border-radius: map-get($defaults, "border-radius");
        border: 1px solid map-get($defaults, "border-color");
      }
    }

  }
}


</style>
