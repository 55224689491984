<template>
  <div class="cart-product row">
    <div class="col-auto">
      <div class="image ratio ratio-4_3">
        <div class="body skeleton_loader"></div>
      </div>
    </div>
    <div class="col">
      <!-- Title -->
      <div class="row justify-content-between">
        <div class="col Aligner">
          <div class="skeleton w-100">
            <div class="line skeleton_loader dark thick w-80"></div>
          </div>
        </div>
        <div class="col-auto row">
          <div class="col-auto Aligner">
            <div class="skeleton">
              <div class="line skeleton_loader dark thick w-50px"></div>
            </div>
          </div>
        </div>
      </div>

      <!-- Details -->
      <div class="row">
        <!-- Preparing time -->
        <div class="col-12">
          <div class="skeleton">
            <div class="line skeleton_loader w-30"></div>
          </div>
        </div>
        <!-- Variant -->
        <div class="col-12">
          <div class="skeleton">
            <div class="line skeleton_loader w-20"></div>
          </div>
        </div>
      </div>

      <!-- Extras -->
      <div class="row extras">
        <div class="col-12">
          <div class="skeleton">
            <div class="line skeleton_loader dark thick w-100px"></div>
          </div>
        </div>

        <div class="skeleton">
          <div class="line skeleton_loader thick w-70"></div>
        </div>
      </div>

      <!--   SubTotal   -->
      <div class="row subtotal">
        <div class="col-12 text-end Aligner items-right">
          <div class="skeleton">
            <div class="line skeleton_loader dark thick w-100px"></div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: "CartProductSkeleton",
}
</script>

<style lang="scss" scoped>
.cart-product {
  .image {
    width: 100px;
    border: 1px solid map-get($defaults, "border-color");
    border-radius: map-get($defaults, "border-radius");
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  .quantity-holder {
    width: 100px;
  }

  //  Extras
  .extras {
    margin-top: map-get($spacings, "default");

    .title {
      margin-bottom: map-get($spacings, "xsmall");
    }

    .extra {
      margin-bottom: map-get($spacings, "small");

      .input-holder {
        width: 100px;
      }
    }
  }
}
</style>
