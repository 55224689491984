<template>
  <!--    Total bar    -->
  <div class="row total-bar">
    <!-- TOTAL WEIGHT -->
    <div class="col-auto">
      <div class="text-uppercase color-secondary">{{ $t("global.weight") }}</div>
      <div v-if="data_ready === true" class="font-weight-bold">{{ total_weight }}
        {{ $t("global.units.kg") }}
      </div>
      <!--     Skeleton       -->
      <div class="skeleton" v-if="data_ready === false">
        <div class="line skeleton_loader dark thick w-100px"></div>
      </div>
    </div>

    <!-- PAYMENT METHOD -->
    <div class="col-auto" v-if="payment_method !== null">
      <div class="text-uppercase color-secondary">{{ $t("cart.payment_method") }}</div>
      <div v-if="data_ready === true" class="font-weight-bold">
        {{ $t("orders.payment_method." + payment_method) }}
      </div>
      <!--     Skeleton       -->
      <div class="skeleton" v-if="data_ready === false">
        <div class="line skeleton_loader dark thick w-100px"></div>
      </div>
    </div>

    <!-- DELIVERY PRICE -->
    <div class="col-auto">
      <div class="text-uppercase color-secondary">{{ $t("products.delivery") }}</div>
      <div v-if="data_ready === true" class="font-weight-bold">
        <span v-if="delivery_price === 0">{{ $t("products.free_shipping") }}</span>
        <span v-if="delivery_price > 0">
                    <span class="font-weight-bold color-black">{{ formated_delivery_price }}</span>
              </span>
        <span v-if="delivery_price === -1">
                {{ $t("cart.shipping_not_available") }}
              </span>
      </div>
      <!--     Skeleton       -->
      <div class="skeleton" v-if="data_ready === false">
        <div class="line skeleton_loader dark thick w-100px"></div>
      </div>
    </div>

    <!-- TOTAL PRICE -->
    <div class="col Aligner items-right">
      <div>
        <div class="text-end text-uppercase color-secondary">{{ $t("global.price.total") }}</div>
        <div v-if="data_ready === true" class="font-weight-bold font-size-big">{{ formated_total_price }}</div>
        <!--     Skeleton       -->
        <div class="skeleton" v-if="data_ready === false">
          <div class="line skeleton_loader dark thick w-100px"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import formatPrice from "@/includes/currency";

export default {
  name: "CartTotalBar",
  props: {
    data_ready: {
      type: Boolean,
      default: () => {
        return false;
      }
    },
    total_weight: {
      type: Number,
      default: () => {
        return 0;
      }
    },
    payment_method: {
      type: String,
      default: () => {
        return null;
      }
    },
    total_price: {
      type: Number,
      default: () => {
        return 0;
      }
    },
    delivery_price: {
      type: Number,
      default: () => {
        return 0;
      }
    },
    currency: null,
  },
  computed: {
    formated_total_price() {
      return formatPrice(this.currency, this.total_price);
    },
    formated_delivery_price: function () {
      return formatPrice(this.currency, this.delivery_price);
    },
  }
}
</script>

<style lang="scss" scoped>
.total-bar {
  border-top: 3px solid map-get($defaults, "border-color");
  padding-top: map-get($spacings, "default");
}
</style>
